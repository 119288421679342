import { useSetAtom } from "jotai";
import { pipe, subscribe } from "wonka";
import { reclaim } from "../reclaim-api";
import { EntitlementType, EntitlementValueTypeMap } from "../reclaim-api/team/Team.types";
import { LoadingErrorData } from "../reclaim-api/types";
import { ProductUsageReport, ProductUsageReportActuals } from "../reclaim-api/Users.types";
import { getProductUsageReportActualsUsageEdition } from "../utils/entitlements";
import { typedEntries } from "../utils/objects";
import { makeLoadableHook } from "../utils/react";
import { reclaimEditionComparitor } from "../utils/sort";
import { useCallbackSafeRef } from "./useCallbackSafeRef";

const loadUsageData = async () => await reclaim.users.usage();

const { useHook, dataAtom } = makeLoadableHook<ProductUsageReport, "usageData">(loadUsageData, {
  dataKey: "usageData",
  errorizer: "Failed to get usage data",
});

// we don't own the function that creates
// this atom.  Have to make sure we call
// onMount in case it already exists.
const { onMount } = dataAtom;

dataAtom.onMount = (update) => {
  const unMount = onMount?.(update);

  let mounted = true;

  const { unsubscribe } = pipe(
    reclaim.users.watchProductUsage$$(),
    subscribe((value) => {
      if (mounted) update(value);
    })
  );

  return () => {
    mounted = false;
    if (!!unsubscribe) unsubscribe();
    unMount?.();
  };
};

export type UseUsageDataReturnType = LoadingErrorData<ProductUsageReport, "usageData"> & {
  reloadUsageData: () => Promise<void>;
  patchUsageData: (
    usage: { [E in EntitlementType]?: EntitlementValueTypeMap[E] },
    reloadAfter?: Promise<unknown>
  ) => Promise<void>;
};

export const useUsageData = (): UseUsageDataReturnType => {
  const hookData = useHook();
  const setData = useSetAtom(dataAtom);

  const reloadUsageData = useCallbackSafeRef(async () => {
    const newData = await loadUsageData();
    if (JSON.stringify(hookData.usageData) !== JSON.stringify(newData)) await setData(newData);
  });

  const patchUsageData = useCallbackSafeRef<UseUsageDataReturnType["patchUsageData"]>(async (usage, reloadAfter) => {
    const { usageData } = hookData;
    if (!usageData) return;

    const { actuals } = usageData;
    let newActuals = actuals;

    typedEntries(actuals).forEach(([entitlement, data]) => {
      if (usage[entitlement] !== undefined && data?.actualValue !== usage[entitlement])
        newActuals = {
          ...newActuals,
          [entitlement]: {
            ...data,
            actualValue: usage[entitlement],
          },
        };
    }, {} as ProductUsageReportActuals);

    if (newActuals !== actuals) {
      const detectedUsageEdition = getProductUsageReportActualsUsageEdition(newActuals);
      const usageEdition = detectedUsageEdition || usageData.usageEdition;
      const newUsageData: ProductUsageReport = {
        ...usageData,
        usageEdition,
        actuals: newActuals,
        overage: reclaimEditionComparitor(usageEdition, usageData.currentEdition) > 0,
      };

      await setData(newUsageData);
      if (reloadAfter) {
        await reloadAfter;
        await reloadUsageData();
      }
    }
  });

  return { ...hookData, patchUsageData, reloadUsageData };
};
