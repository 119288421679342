import {
  Avatar,
  Badge,
  Collapse,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Slide,
  Typography,
} from "@material-ui/core";
import { alpha, makeStyles, Theme } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import clsx from "clsx";
import { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { OnboardingBuffet } from "../components/onboarding/OnboardingBuffet";
import { useAppContext } from "../context/AppContext";
import { useUserContext } from "../context/UserContext";
import { useTeamState } from "../hooks/atoms/useTeam";
import { useOpenIntercom } from "../hooks/useOpenIntercom";
import { useOurRouter } from "../hooks/useOurRouter";
import { useUsageData } from "../hooks/useUsageData";
import { getBillingUrl } from "../utils/router";
import { getUserMonogram } from "../utils/users";
import { EditionBadge } from "./billing/EditionBadge";
import { Link } from "./Link";
import { Tooltip } from "./Tooltip";

const useStyles = makeStyles(
  (theme: Theme) => ({
    listItem: {
      padding: theme.spacing(0.5, "22px", 1.5, 2),
      color: theme.palette.common.white,
      cursor: "pointer",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.075),
      },
      [theme.breakpoints.up("sm")]: {
        paddingBottom: theme.spacing(1),
      },
    },
    nestedListItem: {
      padding: theme.spacing(1, 2),
    },
    avatarListItem: {
      paddingLeft: theme.spacing(8),
    },
    label: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      margin: 0,
      whiteSpace: "nowrap",
    },
    navToggle: {
      opacity: 1,
      right: 8,
      transition: theme.transitions.create(["opacity"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        delay: 25,
      }),
    },
    navToggleClosed: {
      opacity: 0,
      transition: theme.transitions.create(["opacity"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shortest,
        delay: 0,
      }),
    },
    avatar: {
      height: 32,
      marginRight: theme.spacing(2),
      width: 32,
    },
    navIcon: {
      minWidth: 0,
      justifyContent: "center",
      color: theme.palette.common.white,
      opacity: 0.8,
    },
    userName: {
      lineHeight: 1,
      marginTop: theme.spacing(0.25),
      maxWidth: 160,
    },
    listItemText: {
      fontWeight: theme.typography.fontWeightMedium,
      margin: 0,
    },
    divider: {
      backgroundColor: "rgba(255,255,255,.1)",
    },
  }),
  {
    classNamePrefix: "UserNav",
  }
);

export type UserNavProps = {
  className?: string;
  isCollapsible?: boolean;
  showAvatar?: boolean;
  isOnboarding?: boolean;
  onMenuClose?: (event: MouseEvent<EventTarget>) => void;
};

export const UserNav: FC<UserNavProps> = ({
  isCollapsible = true,
  showAvatar = true,
  isOnboarding = false,
  onMenuClose,
}) => {
  const classes = useStyles();

  const {
    state: { nav },
  } = useAppContext();

  const [{ user }, actions] = useUserContext();
  const { usageData } = useUsageData();
  const { team } = useTeamState();
  const router = useOurRouter<{ date?: string; invite?: number }>();
  const monogram = useMemo(() => getUserMonogram(user), [user]);

  const [open, setOpen] = useState<boolean>(() => router?.pathname?.startsWith(getBillingUrl()));
  const [gettingStartedOpen, setGettingStartedOpen] = useState<boolean>(false);

  const {
    openIntercom,
    analytics: {
      state: { segment },
    },
  } = useOpenIntercom();

  const handleHelpClick = useCallback(
    (event: MouseEvent<EventTarget>) => {
      onMenuClose?.(event);
      openIntercom();
    },
    [openIntercom, onMenuClose]
  );

  const handleCloseGettingStarted = useCallback(() => setGettingStartedOpen(false), []);
  const handleLogout = useCallback(() => actions?.logout(), [actions]);

  const handleMenuExpand = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setOpen(!open);
    },
    [open]
  );

  return (
    <List component="nav" disablePadding>
      {!!user &&
        usageData &&
        (!isOnboarding ? (
          <>
            <ListItem className={classes.listItem} component={Link} href="/users/self" underline="none">
              {showAvatar && (
                <ListItemIcon className={classes.navIcon}>
                  <Badge badgeContent={0} color="secondary">
                    <Avatar
                      className={clsx(classes.avatar, showAvatar)}
                      src={user.avatarUrl}
                      alt={user.email}
                      title={user.email}
                    >
                      {monogram}
                    </Avatar>
                  </Badge>
                </ListItemIcon>
              )}
              <Tooltip title={user.email || ""} placement="right">
                <ListItemText
                  primary={
                    <>
                      {team?.pricingSummary.homogeneous && usageData.currentEdition && (
                        <EditionBadge edition={usageData.currentEdition} size="small" />
                      )}
                      <Typography className={classes.userName} noWrap>
                        {user.name}
                      </Typography>
                    </>
                  }
                  className={classes.label}
                />
              </Tooltip>
              {isCollapsible && (
                <ListItemSecondaryAction
                  className={clsx(classes.navToggle, {
                    [classes.navToggleClosed]: !nav.open,
                  })}
                >
                  <IconButton className={classes.navIcon} onClick={handleMenuExpand}>
                    {!open ? (
                      <ChevronRightRoundedIcon fontSize="small" color="inherit" />
                    ) : (
                      <ExpandMoreRoundedIcon fontSize="small" color="inherit" />
                    )}
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
            <Collapse in={(open && nav.open) || !isCollapsible} timeout="auto" unmountOnExit>
              <Link href={getBillingUrl()} underline="none">
                <ListItem
                  className={clsx(classes.listItem, classes.nestedListItem, showAvatar && classes.avatarListItem)}
                >
                  <ListItemText className={classes.listItemText} primary="Billing & Team" disableTypography />
                </ListItem>
              </Link>
              <Link href="/settings/accounts" underline="none">
                <ListItem
                  className={clsx(classes.listItem, classes.nestedListItem, showAvatar && classes.avatarListItem)}
                >
                  <ListItemText className={classes.listItemText} primary="Calendars" disableTypography />
                </ListItem>
              </Link>
              {!isCollapsible && <Divider className={classes.divider} />}
              <ListItem
                className={clsx(classes.listItem, classes.nestedListItem, showAvatar && classes.avatarListItem)}
                onClick={(e) => {
                  setGettingStartedOpen(true);
                  segment?.track("Getting Started Clicked", {
                    category: "Nav",
                  });
                }}
              >
                <ListItemText className={classes.listItemText} primary="Get Started" disableTypography />
              </ListItem>
              <Link href="https://reclaim.ai/webinars-demos" underline="none">
                <ListItem
                  className={clsx(classes.listItem, classes.nestedListItem, showAvatar && classes.avatarListItem)}
                >
                  <ListItemText className={classes.listItemText} primary="Webinars & Demos" disableTypography />
                </ListItem>
              </Link>
              <ListItem
                button
                data-intercom-target="nav-help"
                className={clsx(classes.listItem, classes.nestedListItem, showAvatar && classes.avatarListItem)}
                onClick={handleHelpClick}
              >
                <ListItemText primary="Help" className={classes.listItemText} disableTypography />
              </ListItem>
              <ListItem
                button
                className={clsx(classes.listItem, classes.nestedListItem, showAvatar && classes.avatarListItem)}
                onClick={handleLogout}
              >
                <ListItemText className={classes.listItemText} primary="Log Out" disableTypography />
              </ListItem>
            </Collapse>
          </>
        ) : (
          <ListItem
            button
            className={clsx(classes.listItem, classes.nestedListItem, showAvatar && classes.avatarListItem)}
            onClick={handleLogout}
          >
            <ListItemText className={classes.listItemText} primary="Log Out" disableTypography />
          </ListItem>
        ))}

      {/* Get Started dialog */}
      <Dialog
        fullScreen
        open={gettingStartedOpen}
        onClose={handleCloseGettingStarted}
        TransitionComponent={Slide}
        TransitionProps={{ timeout: 250, direction: "up" } as TransitionProps}
      >
        <OnboardingBuffet onClose={handleCloseGettingStarted} />
      </Dialog>
    </List>
  );
};
